import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import DeleteIcon from "@mui/icons-material/DeleteForever";

import Paper from "@mui/material/Paper";
import { Box, Button, IconButton, TextField, Typography } from "@mui/material";
import {
  deleteExercise,
  fetchExercises,
  saveNewExercise,
} from "../../services/WodService";
import Lottie from "react-lottie";
import RocketImage from "../../assets/empty.json";
import LoadingImage from "../../assets/loading.json";
import { SystemDialog } from "../../utils/Dialog";
import { useSnackbar } from "notistack";
import { withAuth } from "../../hoc";

const emptyOptions = {
  loop: true,
  autoplay: true,
  animationData: RocketImage,
};

const loadingOptions = {
  loop: true,
  autoplay: true,
  animationData: LoadingImage,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.action.hover,
  maxHeight: 100,
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Exercises = () => {
  const [exercises, setExercises] = useState<
    Array<{ name: string; id: string }>
  >([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [newExercise, setNewExerice] = useState<string>("");

  const [currentExercise, setCurrentExercise] = useState<string | undefined>(
    undefined
  );

  const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] =
    useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();

  const getAllExercises = () => {
    setLoading(true);
    fetchExercises()
      .then((exerciseList) => {
        setExercises(exerciseList);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        alert("Something went wrong! Please try again later...");
      });
  };

  useEffect(() => {
    getAllExercises();
  }, []);

  const onDeleteExercise = (exercise: string) => {
    setCurrentExercise(exercise);
    onExerciseDeleteClicked();
  };

  const onExerciseDeleteClicked = () => {
    setShowDeleteConfirmDialog(true);
  };

  const handleDeleteConfirm = () => {
    deleteExercise(currentExercise || "")
      .then(() => {
        setShowDeleteConfirmDialog(false);
        enqueueSnackbar("Exercise deleted successfully!");
        setCurrentExercise(undefined);
        getAllExercises();
      })
      .catch(() => {
        setShowDeleteConfirmDialog(false);
      });
  };

  const onNewExerciseChange = (event: any) => {
    setNewExerice(event.target.value);
  };

  const handleNewExerciseAdd = () => {
    if (
      exercises.findIndex(
        (ex) => ex.id === newExercise.toLowerCase().trim()
      ) === -1
    ) {
      saveNewExercise(newExercise)
        .then(() => {
          enqueueSnackbar(`${newExercise} saved successfully!`);
          setNewExerice("");
          getAllExercises();
        })
        .catch(() => alert("Something went wrong, please try again later."));
    } else {
      alert("This exercise already exist!");
    }
  };

  const EmptyData = () => {
    return (
      <Box style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
        <Lottie options={emptyOptions} height={100} width={100} />
        <Typography variant="body2" sx={{ textAlign: "center" }}>
          No workouts found. Please go ahead and create one to view here...
        </Typography>
      </Box>
    );
  };

  const Loading = () => {
    return (
      <Box style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
        <Lottie options={loadingOptions} height={300} width={300} />
        <Typography variant="body2" sx={{ textAlign: "center" }}>
          Hang tight! We are trying to get your past workouts list...
        </Typography>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        padding: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          maxWidth: "100%",
          maxHeight: "80vh",
          flexDirection: "row",
          paddingTop: 4,
        }}
      >
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography variant="h5">Your saved Exercises</Typography>
          <Box sx={{ height: 10 }} />
          {exercises.length === 0 || loading ? (
            loading ? (
              <Loading />
            ) : (
              <EmptyData />
            )
          ) : (
            <TableContainer
              sx={{ minHeight: "60vh", maxHeight: "60vh" }}
              component={Paper}
            >
              <Table sx={{ maxWidth: "100%" }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Exercise Name</TableCell>
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {exercises.map((exercise) => (
                    <StyledTableRow
                      key={exercise.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <StyledTableCell component="th" scope="row">
                        {exercise.name}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <IconButton
                          onClick={() => onDeleteExercise(exercise.name)}
                          sx={{ paddingRight: 1 }}
                          size="medium"
                          edge="start"
                          color="inherit"
                          aria-label="menu"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            paddingLeft: 6,
            paddingRight: 6,
            flexDirection: "column",
          }}
        >
          <Typography variant="h5">Add a new Exercises</Typography>
          <Box sx={{ height: 3 }} />
          <TextField
            margin="dense"
            id="exercise-add"
            label="New Exercise"
            value={newExercise}
            type="text"
            onChange={onNewExerciseChange}
            fullWidth
            variant="filled"
          />
          <Box sx={{ height: 5 }} />
          <Button
            sx={{ alignSelf: "flex-end" }}
            color="secondary"
            variant="outlined"
            onClick={handleNewExerciseAdd}
          >
            Add New Exercise
          </Button>
        </Box>
        <SystemDialog
          component={
            <Typography variant="overline">
              {`Are you sure you want to delete this exercise ?`}
            </Typography>
          }
          open={showDeleteConfirmDialog}
          handleClose={() => setShowDeleteConfirmDialog(false)}
          handleConfirm={handleDeleteConfirm}
          heading="Delete Exercise"
        />
      </Box>
    </Box>
  );
};

export default withAuth(Exercises);
