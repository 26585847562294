import * as React from "react";
import { AppBar, Toolbar, Box, Typography } from "@mui/material";

const AppHeader = ({ title }: { title?: string }) => {
  return (
    <AppBar sx={{ maxHeight: 80 }} color="inherit" position="relative">
      <Toolbar>
        <Box
          sx={{
            flexDirection: "row",
            alignContent: "center",
          }}
        >
          <Typography sx={{ paddingTop: 2.5 }} variant="h5" component="div">
            Nikk Fit World System
          </Typography>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default AppHeader;
