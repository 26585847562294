import { ThemeOptions } from "@mui/material";
import { grey, orange } from "@mui/material/colors";

export const theme: ThemeOptions = {
  palette: {
    mode: "dark",
    text: {
      primary: "#FFF",
      secondary: grey[500],
    },
    divider: grey[700],
    background: {
      default: grey[900],
      paper: grey[900],
    },
    info: {
      dark: grey[400],
      main: grey[400],
      light: grey[700],
    },
    secondary: {
      dark: orange[300],
      main: orange[300],
    },
  },
};
