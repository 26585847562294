import React from "react";
import { Box } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { CreateWOD } from "./CreateWOD";
import ViewWODs from "./ViewWODs";
import { useWod } from "../../context/WODContext";
export const WOD = () => {
  const [tabItemValue, setTabItemValue] = React.useState(0);

  const { resetWodToToday } = useWod();

  const onTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabItemValue(newValue);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        padding: 2,
      }}
    >
      <Tabs
        value={tabItemValue}
        onChange={onTabChange}
        aria-label="basic tabs example"
      >
        <Tab value={0} label="Past Workouts" />
        <Tab value={1} label="Create Workout" />
      </Tabs>
      {tabItemValue === 0 ? (
        <ViewWODs onEditWodSelected={() => setTabItemValue(1)} />
      ) : (
        <CreateWOD />
      )}
    </Box>
  );
};
