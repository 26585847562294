import {
  getDoc,
  doc,
  deleteDoc,
  collection,
  getDocs,
  setDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import { ExerciseSet, WOD } from "../pages/company/types";
import { firestoreDb } from "../App";
import { getFormattedDate } from "../utils/commons";

export const fetchExercises = (): Promise<
  Array<{ name: string; id: string }>
> => {
  const docRef = doc(firestoreDb, "exercises", "LKmbFeyPqsr5FC1Z2T1B");
  return getDoc(docRef)
    .then((docSnap) => {
      if (docSnap.data()) {
        let exerciseList = docSnap.data()?.exercises || [];
        exerciseList = exerciseList.map((_exercise: string) => {
          return {
            name: _exercise,
            id: _exercise.toLowerCase(),
          };
        });
        return Promise.resolve(exerciseList);
      }
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};

export const getTodaysWod = (): Promise<Array<ExerciseSet>> => {
  const formattedDate = getFormattedDate();
  const docRef = doc(firestoreDb, "wods", formattedDate);
  return getDoc(docRef)
    .then((docSnap) => {
      if (docSnap.data()) {
        return Promise.resolve(docSnap.data()?.sets || []);
      } else {
        return Promise.resolve([]);
      }
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};

export const deleteWod = (date?: string) => {
  let dateToDelete = getFormattedDate();
  if (date) {
    dateToDelete = date;
  }

  return deleteDoc(doc(firestoreDb, "wods", dateToDelete))
    .then((deleted) => {
      return Promise.resolve();
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};

export const fetchPastWods = () => {
  const docs: any[] = [];
  const docRef = collection(firestoreDb, "wods");
  return getDocs(docRef)
    .then((docsSnap) => {
      docsSnap.forEach((_doc) => {
        docs.push(_doc.data());
      });
      return Promise.resolve(docs);
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};

export const cloneWorkout = (wod: WOD): Promise<boolean> => {
  const formattedDate = getFormattedDate();
  return setDoc(doc(firestoreDb, "wods", formattedDate), {
    date: formattedDate,
    sets: wod.sets,
    duration: wod.duration,
  })
    .then(() => {
      return Promise.resolve(true);
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};

export const saveNewExercise = (exercise: string): Promise<boolean> => {
  const exerciseListRef = doc(firestoreDb, "exercises", "LKmbFeyPqsr5FC1Z2T1B");

  return updateDoc(exerciseListRef, {
    exercises: arrayUnion(exercise),
  })
    .then(() => {
      return Promise.resolve(true);
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};

export const deleteExercise = (exercise: string): Promise<boolean> => {
  const exerciseListRef = doc(firestoreDb, "exercises", "LKmbFeyPqsr5FC1Z2T1B");

  return updateDoc(exerciseListRef, {
    exercises: arrayRemove(exercise),
  })
    .then(() => {
      return Promise.resolve(true);
    })
    .catch((e) => {
      return Promise.reject(e);
    });
};
