import React, { useEffect, useState } from "react";

export interface TimerProps {
  timeInSeconds: number;
  onTimerFinish: () => void;
}

const Timer = ({ timeInSeconds, onTimerFinish }: TimerProps) => {
  const [time, setTime] = useState<number>(timeInSeconds);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (time === 0) {
        onTimerFinish();
      }

      setTime(time - 1);
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  });

  return <>{time}</>;
};

export default Timer;
