import React, { createContext, useState, useEffect, useContext } from "react";
import { getAuth, signOut } from "firebase/auth";
import { ExerciseSet } from "../pages/company/types";

interface IAuthContext {
  isAuthenticated: boolean;
  logoutUser: () => void;
  accessToken: string | undefined;
  wodSets: Array<ExerciseSet>;
  setWodSets: (sets: Array<ExerciseSet>) => void;
  exercises: Array<string>;
  setExercises: (exercises: Array<string>) => void;
}

const AuthContext = createContext<IAuthContext>({
  isAuthenticated: false,
  logoutUser: () => {},
  accessToken: "",
  wodSets: [],
  setWodSets: () => {},
  exercises: [],
  setExercises: () => {},
});

export const useAuth = (): IAuthContext => useContext(AuthContext);

export const AuthProvider = ({ children }: { children: JSX.Element }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [accessToken, setAccessToken] = useState<string | undefined>(undefined);
  const [wodSets, setWodSets] = useState<Array<ExerciseSet>>([]);
  const [exercises, setExercises] = useState<Array<string>>([]);

  const auth = getAuth();

  const logoutUser = (): void => {
    signOut(auth).then(() => {
      setIsAuthenticated(false);
      setAccessToken(undefined);
    });
  };

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      user?.getIdToken().then((token) => {
        setAccessToken(token);
        setIsAuthenticated(true);
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        accessToken,
        logoutUser,
        wodSets,
        setWodSets,
        setExercises,
        exercises,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
