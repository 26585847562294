import {
  Box,
  Button,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";

import React, { useEffect, useMemo, useState } from "react";
import { CreateExercise } from "./CreateExercise";
import { Exercise } from "./types";
import { ExerciseSetList } from "./ExerciseSetList";
import { useWod } from "../../context/WODContext";
import { useTranslation } from "react-i18next";

export const CreateSet = () => {
  const [name, setName] = useState<string>("");
  const [duration, setDuration] = useState<string>("0");
  const [durationType, setDurationType] = useState<string>("Minutes");
  const [setsType, setSetsType] = useState<string>("COUNT");
  const [reps, setReps] = useState<number>(1);

  const [showExerciseCreate, setShowExerciseCreate] = useState<boolean>(false);

  const [exercisesForSet, setExercisesForSet] = useState<Array<Exercise>>([]);

  const [currentExerciseItemToEdit, setCurrentExerciseItemToEdit] =
    useState<Exercise | null>(null);

  const {
    wodSets,
    setWodSets,
    exercises,
    currentSetIndex,
    setCurrentSetIndex,
  } = useWod();

  const searchableExercises: Array<{ label: string }> = useMemo(() => {
    return exercises.map((item) => ({ label: item.name }));
  }, [exercises]);

  const { t } = useTranslation();

  const handleDurationTypeChange = (event: SelectChangeEvent) => {
    setDurationType(event.target.value as string);
  };

  const handleSetTypeChange = (event: SelectChangeEvent) => {
    setSetsType(event.target.value as string);
  };

  const handleRepsChange = (event: SelectChangeEvent) => {
    setReps(Number(event.target.value));
  };

  const onNameChange = (event: any) => {
    setName(event.target.value);
  };

  const onDurationChange = (event: any) => {
    setDuration(event.target.value);
  };

  const onAddExerciseClicked = () => {
    setShowExerciseCreate(true);
  };

  const handleExerciseDelete = (id: number) => {
    let remainingExercises = exercisesForSet.filter((v) => v.id !== id);
    remainingExercises = remainingExercises.map((ex, i) => {
      return {
        ...ex,
        id: i,
      };
    });
    setExercisesForSet(remainingExercises);
  };

  const handleExerciseEdit = (id: number) => {
    let editableExerciseSet = exercisesForSet.find((v) => v.id === id) || null;
    setCurrentExerciseItemToEdit(editableExerciseSet);
    setShowExerciseCreate(true);
  };

  const handleSetSave = () => {
    if (exercisesForSet.length === 0) {
      alert("Please add exercises for the set.");
      return;
    }
    let durationInMillis = 0;
    if (durationType === "Minutes") {
      durationInMillis = Number(duration) * 60000;
    } else {
      durationInMillis = Number(duration) * 1000;
    }
    if (exercisesForSet.length > 0 && name.length > 0 && Number(duration) > 0) {
      let sets = wodSets.slice(0);
      sets = sets.map((ex, i) => {
        return {
          ...ex,
          id: ++i,
        };
      });
      if (currentSetIndex !== -1) {
        sets[currentSetIndex] = {
          duration: durationInMillis,
          exercises: exercisesForSet,
          id: sets.length + 1,
          name,
          repetitions: reps,
          type: setsType,
        };
      } else {
        sets.push({
          duration: durationInMillis,
          exercises: exercisesForSet,
          id: sets.length + 1,
          name,
          repetitions: reps,
          type: setsType,
        });
      }
      setCurrentSetIndex(-1);
      setWodSets(sets);
      resetUI();
    } else {
      alert("Please enter proper details to go ahead.");
    }
  };

  const handleOnExerciseEdit = () => {};

  const handleOnExerciseRemove = () => {
    setShowExerciseCreate(false);
    setCurrentExerciseItemToEdit(null);
  };

  const handleOnExerciseAdd = (exercise: Exercise) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    let _exercises = exercisesForSet.slice(0);
    if (currentExerciseItemToEdit) {
      const editingItemIndex = _exercises.findIndex(
        (item) => item.id === currentExerciseItemToEdit.id
      );
      _exercises[editingItemIndex] = exercise;
      _exercises = _exercises.slice(0);
    } else {
      _exercises.push(exercise);
    }
    setExercisesForSet(_exercises);
  };

  const resetUI = () => {
    setName("");
    setDuration("0");
    setDurationType("Minutes");
    setSetsType("COUNT");
    setReps(1);
    setShowExerciseCreate(false);
    setExercisesForSet([]);
  };

  const handleReset = () => {
    resetUI();
    setCurrentExerciseItemToEdit(null);
    setCurrentSetIndex(-1);
  };

  const populateEditableSet = () => {
    const selectedSet = wodSets[currentSetIndex];
    setName(selectedSet.name);
    let selectedSetDuration = 0;
    if (selectedSet.duration >= 60000) {
      selectedSetDuration = selectedSet.duration / 60000;
      setDurationType("Minutes");
    } else {
      selectedSetDuration = selectedSet.duration / 1000;
      setDurationType("Seconds");
    }
    setDuration(selectedSetDuration.toString());
    setSetsType(selectedSet.type);
    setReps(selectedSet.repetitions);
    setExercisesForSet(selectedSet.exercises);
  };

  useEffect(() => {
    if (currentSetIndex !== -1) {
      populateEditableSet();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSetIndex]);

  return (
    <Box sx={{ flex: 1, flexDirection: "row", display: "flex" }}>
      <Box sx={{ width: "50%" }}>
        <Box
          sx={{
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {t("create_wod")}
          </Typography>
          <Button color="info" variant="outlined" onClick={handleReset}>
            Reset Form
          </Button>
        </Box>
        <TextField
          margin="dense"
          id="name"
          label="Enter Name"
          onChange={onNameChange}
          value={name}
          type="tel"
          fullWidth
          variant="filled"
        />
        <Box
          sx={{
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="body1" color="text.secondary">
            Select Repititions
          </Typography>
          <Select
            sx={{ width: 200, height: 60, padding: 2 }}
            value={reps.toString()}
            label="Reps"
            variant="standard"
            onChange={handleRepsChange}
          >
            {["1", "2", "3", "4", "5"].map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </Box>

        <Box sx={{ flexDirection: "row", display: "flex" }}>
          <TextField
            margin="dense"
            id="name"
            label="Set Duration"
            value={duration}
            type="number"
            onChange={onDurationChange}
            fullWidth
            variant="filled"
          />
          <Select
            sx={{ width: 150, height: 60, padding: 2 }}
            value={durationType}
            label="Min/Sec"
            variant="standard"
            onChange={handleDurationTypeChange}
          >
            <MenuItem value="Minutes">Minutes</MenuItem>
            <MenuItem value="Seconds">Seconds</MenuItem>
          </Select>
          <Box sx={{ width: "10px" }} />
        </Box>
        <Box
          sx={{
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="body1" color="text.secondary">
            Select Set Type
          </Typography>
          <Select
            sx={{ width: 200, height: 60 }}
            value={setsType}
            label="Select Set Type"
            variant="standard"
            onChange={handleSetTypeChange}
          >
            <MenuItem value="COUNT">COUNT</MenuItem>
            <MenuItem value="AMRAP">AMRAP</MenuItem>
            <MenuItem value="TIME">TIME</MenuItem>
          </Select>
        </Box>
        {exercisesForSet.length > 0 && (
          <ExerciseSetList
            onExerciseEditClicked={handleExerciseEdit}
            onExerciseDeleteClicked={handleExerciseDelete}
            exercises={exercisesForSet}
          />
        )}
        <Box sx={{ height: 20 }} />
        <Box
          sx={{
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Button
            color="secondary"
            variant="outlined"
            onClick={onAddExerciseClicked}
          >
            Add Exercise
          </Button>
          <Box sx={{ width: 10 }} />
          <Button color="primary" variant="outlined" onClick={handleSetSave}>
            {currentSetIndex !== -1 ? "Update Set" : "Save Set"}
          </Button>
        </Box>
      </Box>
      <Box sx={{ width: 100 }} />
      <Box sx={{ width: "50%" }}>
        {showExerciseCreate && (
          <CreateExercise
            id={
              currentExerciseItemToEdit
                ? currentExerciseItemToEdit.id
                : exercisesForSet.length
            }
            onExerciseAdd={handleOnExerciseAdd}
            onExerciseRemove={handleOnExerciseRemove}
            onExerciseEdit={handleOnExerciseEdit}
            setType={setsType}
            isEditing={currentExerciseItemToEdit !== null}
            exercises={searchableExercises}
            currentExerciseItemToEdit={currentExerciseItemToEdit}
          />
        )}
      </Box>
    </Box>
  );
};
