import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { CreateSet } from "./CreateSet";
import { SetsList } from "./SetsList";
import { firestoreDb } from "../../App";
import { getDoc, doc, setDoc, deleteDoc } from "firebase/firestore";
import { useWod } from "../../context/WODContext";
import {
  getFormattedDate,
  getReadableShortDate,
  getWodDuration,
} from "../../utils/commons";
import SendIcon from "@mui/icons-material/Save";
import { SystemDialog } from "../../utils/Dialog";
import { useSnackbar } from "notistack";
import { fetchExercises, getTodaysWod } from "../../services/WodService";
import { useTheme } from "@mui/material/styles";

export const CreateWOD = () => {
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
  const theme = useTheme();

  const {
    setExercises,
    wodSets,
    setWodSets,
    setWodsLoading,
    wodDate,
    isEditing: isWodEditing,
  } = useWod();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    fetchExercises()
      .then((exercises) => {
        setExercises(exercises);
      })
      .catch((e) => {
        console.log("#### e", e);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUploadClick = () => {
    setShowConfirmDialog(true);
  };

  const handleConfirmDialog = () => {
    const formattedDate = isWodEditing ? wodDate : getFormattedDate();
    setDoc(doc(firestoreDb, "wods", formattedDate), {
      date: formattedDate,
      sets: wodSets,
      duration: getWodDuration(wodSets),
    })
      .then(() => {
        setShowConfirmDialog(false);
        enqueueSnackbar("WOD saved successfully!");
      })
      .catch((e) => {
        setShowConfirmDialog(false);
      });
  };

  const fetchTodaysWod = () => {
    setWodsLoading(true);
    getTodaysWod()
      .then((wodSetsList) => {
        setWodSets(wodSetsList);
        setWodsLoading(false);
      })
      .catch((er) => {
        setWodsLoading(false);
      });
  };

  useEffect(() => {
    if (!isWodEditing) {
      fetchTodaysWod();
    }
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        padding: 2,
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <CreateSet />
        <Box sx={{ width: 100 }} />
        <Box
          style={{
            width: 400,
            display: "flex",
            flexDirection: "column",
          }}
        >
          {wodSets.length > 0 && (
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{ color: theme.palette.secondary.main }}
              >
                {isWodEditing
                  ? getReadableShortDate(wodDate)
                  : `Today: ${getReadableShortDate()}`}
              </Typography>
              <Button
                color="primary"
                endIcon={<SendIcon />}
                variant="outlined"
                onClick={handleUploadClick}
              >
                Upload WOD
              </Button>
            </Box>
          )}
          <Box sx={{ height: 10 }} />
          <SetsList />
        </Box>
      </Box>
      <SystemDialog
        component={
          <Typography variant="overline">
            {isWodEditing
              ? `Are you sure you want to update this workout plan ?`
              : `Are you sure you want to save todays workout ?`}
          </Typography>
        }
        open={showConfirmDialog}
        handleClose={() => setShowConfirmDialog(false)}
        handleConfirm={handleConfirmDialog}
        heading="Save Workout"
      />
    </Box>
  );
};
