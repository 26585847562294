import {
  Box,
  Button,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { Exercise } from "./types";

export const CreateExercise = ({
  id,
  exercises,
  setType,
  onExerciseRemove,
  onExerciseAdd,
  onExerciseEdit,
  isEditing,
  currentExerciseItemToEdit,
}: {
  exercises: Array<{ label: string }>;
  setType: string;
  id: number;
  isEditing: boolean;
  onExerciseRemove: () => void;
  onExerciseEdit: () => void;
  onExerciseAdd: (exercise: Exercise) => void;
  currentExerciseItemToEdit: Exercise | null;
}) => {
  const [exerciseName, setExerciseName] = useState<string>("");
  const [duration, setDuration] = useState<string>("0");
  const [durationType, setDurationType] = useState<string>("Minutes");
  const [breakDurationType, setBreakDurationType] = useState<string>("Minutes");
  const [breakDuration, setBreakDuration] = useState<string>("0");
  const [reps, setReps] = useState<number>(1);

  const handleDurationTypeChange = (event: SelectChangeEvent) => {
    setDurationType(event.target.value as string);
  };

  const handleBreakTimeTypeChange = (event: SelectChangeEvent) => {
    setBreakDurationType(event.target.value as string);
  };

  const onRepsChange = (event: any) => {
    setReps(Number(event.target.value));
  };

  const handleExerciseSelect = (
    event: SyntheticEvent<Element, Event>,
    value: { label: string } | null
  ) => {
    // console.log("### event = ", event.target.value);
    setExerciseName(value?.label || "");
  };

  const onDurationChange = (event: any) => {
    setDuration(event.target.value);
  };

  const onBreakTimeChange = (event: any) => {
    setBreakDuration(event.target.value);
  };

  const onAddExercise = () => {
    let durationInMillis = 0,
      breakDurationInMillis = 0;

    if (durationType === "Minutes") {
      durationInMillis = Number(duration) * 60000;
    } else {
      durationInMillis = Number(duration) * 1000;
    }

    if (breakDurationType === "Minutes") {
      breakDurationInMillis = Number(breakDuration) * 60000;
    } else {
      breakDurationInMillis = Number(breakDuration) * 1000;
    }

    if (exerciseName.length > 0 && (reps > 0 || Number(duration) > 0)) {
      onExerciseAdd({
        id: isEditing ? id : ++id,
        name: exerciseName,
        duration: durationInMillis,
        breakTime: breakDurationInMillis,
        repetitions: reps,
        type: setType === "TIME" ? "TIME_BOUND" : "REP_BOUND",
      });
      onExerciseRemove();
    } else {
      alert("Please enter proper details to go ahead.");
    }
  };

  const populateEditableExercise = () => {
    if (currentExerciseItemToEdit) {
      setExerciseName(currentExerciseItemToEdit?.name || "");
      let exerciseDuration = 0;
      let exerciseBreakDuration = 0;
      if (currentExerciseItemToEdit?.duration >= 60000) {
        exerciseDuration = currentExerciseItemToEdit.duration / 60000;
        setDurationType("Minutes");
      } else {
        exerciseDuration = currentExerciseItemToEdit.duration / 1000;
        setDurationType("Seconds");
      }
      setDuration(exerciseDuration.toString());

      if (currentExerciseItemToEdit?.breakTime >= 60000) {
        exerciseBreakDuration = currentExerciseItemToEdit.breakTime / 60000;
        setBreakDurationType("Minutes");
      } else {
        exerciseBreakDuration = currentExerciseItemToEdit.breakTime / 1000;
        setBreakDurationType("Seconds");
      }
      setBreakDuration(exerciseBreakDuration.toString());

      setReps(currentExerciseItemToEdit.repetitions);
    }
  };

  useEffect(() => {
    if (currentExerciseItemToEdit) {
      populateEditableExercise();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentExerciseItemToEdit]);

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          flexDirection: "row",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="body1" color="text.secondary">
          Select Exercise
        </Typography>
        {/* <Select
          sx={{ width: 300, height: 60, padding: 2 }}
          value={exerciseName}
          label="Exercise"
          variant="standard"
          onChange={handleExerciseSelect}
        >
          {exercises.map((item, index) => (
            <MenuItem key={index} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select> */}

        <Autocomplete
          disablePortal
          id="combo-box-demo"
          onChange={handleExerciseSelect}
          options={exercises}
          sx={{ width: 350 }}
          renderInput={(params) => <TextField {...params} label="Exercise" />}
        />
      </Box>
      {setType === "TIME" ? (
        <Box>
          <Box sx={{ flexDirection: "row", display: "flex" }}>
            <TextField
              margin="dense"
              id="name"
              label="Set Duration"
              value={duration}
              type="number"
              onChange={onDurationChange}
              fullWidth
              variant="filled"
            />
            <Select
              sx={{ width: 150, height: 60, padding: 2 }}
              value={durationType}
              label="Min/Sec"
              variant="standard"
              onChange={handleDurationTypeChange}
            >
              <MenuItem value="Minutes">Minutes</MenuItem>
              <MenuItem value="Seconds">Seconds</MenuItem>
            </Select>
            <Box sx={{ width: "10px" }} />
          </Box>
          <Box sx={{ flexDirection: "row", display: "flex" }}>
            <TextField
              margin="dense"
              id="name"
              label="Set Break Duration"
              value={breakDuration}
              type="number"
              onChange={onBreakTimeChange}
              fullWidth
              variant="filled"
            />
            <Select
              sx={{ width: 150, height: 60, padding: 2 }}
              value={breakDurationType}
              label="Min/Sec"
              variant="standard"
              onChange={handleBreakTimeTypeChange}
            >
              <MenuItem value="Minutes">Minutes</MenuItem>
              <MenuItem value="Seconds">Seconds</MenuItem>
            </Select>
            <Box sx={{ width: "10px" }} />
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <TextField
            margin="dense"
            id="reps"
            label="Enter Repetitions"
            value={reps}
            type="number"
            onChange={onRepsChange}
            fullWidth
            variant="filled"
          />
        </Box>
      )}
      <Box
        sx={{
          flexDirection: "row",
          display: "flex",
          paddingTop: 2,
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Button
          sx={{ alignSelf: "flex-end" }}
          color="error"
          variant="outlined"
          onClick={onExerciseRemove}
        >
          Remove Exercise
        </Button>
        <Button
          sx={{ alignSelf: "flex-end" }}
          color="primary"
          variant="outlined"
          onClick={onAddExercise}
        >
          {currentExerciseItemToEdit ? "Update Exercise" : "Add Exercise"}
        </Button>
      </Box>
    </Box>
  );
};
