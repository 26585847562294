import React, { ReactNode, useEffect, useState } from "react";

import {
  Box,
  Grid,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SvgIconTypeMap,
} from "@mui/material";
import Lottie from "react-lottie";
import RocketImage from "../assets/gym_login_animation.json";
import { useNavigate } from "react-router-dom";

import Subscriptions from "@mui/icons-material/Subscriptions";
import MembersIcon from "@mui/icons-material/Groups";
import ExercisesIcon from "@mui/icons-material/SelfImprovement";
import WODIcon from "@mui/icons-material/FitnessCenter";
import Logout from "@mui/icons-material/Logout";
import DashboardIcon from "@mui/icons-material/Dashboard";

import Movie from "@mui/icons-material/Movie";
import { useTheme } from "@mui/material/styles";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { useAuth } from "../context/AuthContext";

interface MenuItem {
  label: string;
  Icon: OverridableComponent<SvgIconTypeMap<{}, "svg">> & { muiName: string };
  id: number;
}

const menuItems: Array<MenuItem> = [
  {
    Icon: DashboardIcon,
    label: "Dashboard",
    id: 0,
  },
  {
    Icon: MembersIcon,
    label: "Members",
    id: 1,
  },
  {
    Icon: ExercisesIcon,
    label: "Exercises",
    id: 2,
  },
  {
    Icon: WODIcon,
    label: "Workout of the Day",
    id: 3,
  },
  {
    Icon: Subscriptions,
    label: "Subscriptions",
    id: 4,
  },
  {
    Icon: Movie,
    label: "Videos",
    id: 5,
  },
  {
    Icon: Logout,
    id: 6,
    label: "Logout",
  },
];

export const Drawer = () => {
  const navigate = useNavigate();
  // let location = useLocation();
  const theme = useTheme();
  const { logoutUser } = useAuth();

  const [selectedMenu, setSelectedMenu] = useState<number>(0);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: RocketImage,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  // useEffect(() => {
  //   console.log("location", location);
  // }, [location]);

  const onMenuItemClick = (id: number) => {
    switch (id) {
      case 0:
        setSelectedMenu(id);
        navigate("/");
        break;
      case 1:
      case 4:
      case 5:
        alert("Work in progress");
        break;
      case 2:
        setSelectedMenu(id);
        navigate("exercise");
        break;
      case 3:
        setSelectedMenu(id);
        navigate("wod");
        break;
      case 6:
        logoutUser();
        break;
    }
  };

  return (
    <Box
      sx={{ width: 250, borderRight: 2, borderColor: "#CFCCCC" }}
      role="presentation"
    >
      <Grid
        sx={{
          padding: 2,
          display: { xs: "none", sm: "flex", md: "flex" },
        }}
        flexGrow={1}
        display="flex"
        flexDirection="column"
      >
        <Lottie options={defaultOptions} height={200} width={200} />
      </Grid>
      <Divider />
      <List>
        {menuItems.map(({ label, Icon, id }) => (
          <ListItem key={label} disablePadding>
            <ListItemButton onClick={() => onMenuItemClick(id)}>
              <ListItemIcon>
                {<Icon color={id === selectedMenu ? "primary" : "info"} />}
              </ListItemIcon>
              <ListItemText
                sx={{
                  color:
                    id === selectedMenu
                      ? theme.palette.primary.main
                      : theme.palette.info.main,
                }}
                primary={label}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};
