import * as React from "react";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import Navigation from "./Navigation/Navigation";
import { AnalyticsProvider, AuthProvider } from "./context";
import CssBaseline from "@mui/material/CssBaseline";
import { getFirestore } from "firebase/firestore";
import { SnackbarProvider } from "notistack";
import { appTheme } from "./theme";
import { ThemeProvider } from "@mui/material/styles";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};
const app = initializeApp(firebaseConfig);
export const firestoreDb = getFirestore(app);

function App() {
  const analytics = getAnalytics();

  return (
    <AnalyticsProvider analyticsInstance={analytics}>
      <SnackbarProvider>
        <ThemeProvider theme={appTheme}>
          <CssBaseline />
          <AuthProvider>
            <Navigation />
          </AuthProvider>
        </ThemeProvider>
      </SnackbarProvider>
    </AnalyticsProvider>
  );
}

export default App;
