import React, { useState } from "react";
import {
  Box,
  Card,
  IconButton,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import RefreshIcon from "@mui/icons-material/Refresh";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import Lottie from "react-lottie";
import RocketImage from "../../assets/empty.json";
import { ExerciseSet } from "./types";
import { millisToReadableFormat } from "../../utils/commons";
import { useWod } from "../../context/WODContext";
import { deleteWod, getTodaysWod } from "../../services/WodService";
import { SystemDialog } from "../../utils/Dialog";
import { useSnackbar } from "notistack";

const emptyOptions = {
  loop: true,
  autoplay: true,
  animationData: RocketImage,
};

const SetView = ({ set, index }: { set: ExerciseSet; index: number }) => {
  const { setCurrentSetIndex, wodSets, setWodSets } = useWod();

  const onEditClick = () => {
    setCurrentSetIndex(index);
  };

  const onDeleteClick = () => {
    let remainingWodSets = wodSets.filter((v) => v.id !== set.id);
    remainingWodSets = remainingWodSets.map((ex, i) => {
      return {
        ...ex,
        id: ++i,
      };
    });
    setWodSets(remainingWodSets);
  };

  return (
    <Box>
      <Card
        variant="outlined"
        sx={{
          paddingLeft: 2,
          paddingTop: 2,
          borderRadius: 3,
        }}
      >
        <Typography variant="button">
          {set.name} x {set.repetitions}
        </Typography>
        <Typography variant="subtitle2">
          {millisToReadableFormat(set.duration)}
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton onClick={onEditClick}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={onDeleteClick}>
            <DeleteIcon />
          </IconButton>
        </Box>
      </Card>
      <Box sx={{ height: 10 }} />
    </Box>
  );
};

export const SetsList = () => {
  const {
    wodSets,
    loading,
    setWodSets,
    setWodsLoading,
    isEditing: isWodEditing,
    resetWodToToday,
  } = useWod();
  const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] =
    useState<boolean>(false);
  const [showRefreshConfirmDialog, setShowRefreshConfirmDialog] =
    useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();

  const handleRefresh = () => {
    setWodsLoading(true);
    getTodaysWod()
      .then((wodSetsList) => {
        setWodSets(wodSetsList);
        resetWodToToday();
        setWodsLoading(false);
        setShowRefreshConfirmDialog(false);
      })
      .catch((er) => {
        console.log("### error loading todays wods - ", er);
        setWodsLoading(false);
        setShowRefreshConfirmDialog(false);
      });
  };

  const handleOnRefresh = () => {
    if (wodSets.length > 0) {
      setShowRefreshConfirmDialog(true);
    } else {
      handleRefresh();
    }
  };

  const handleOnDelete = () => {
    setShowDeleteConfirmDialog(true);
  };

  const handleConfirmDialog = () => {
    deleteWod()
      .then(() => {
        setShowDeleteConfirmDialog(false);
        enqueueSnackbar("WOD deleted successfully!");
        handleOnRefresh();
      })
      .catch(() => {
        setShowDeleteConfirmDialog(false);
      });
  };

  return (
    <Box sx={{ width: 400, alignItems: "flex-end" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6">
          {isWodEditing ? "Workout plan" : "Todays Workout plan"}
        </Typography>
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <Tooltip title="Load todays workout">
            <IconButton
              onClick={handleOnRefresh}
              size="medium"
              edge="start"
              color="inherit"
              aria-label="menu"
            >
              <RefreshIcon />
            </IconButton>
          </Tooltip>
          <Box width={10} />
          {wodSets.length > 0 && !isWodEditing && (
            <Tooltip title="Delete">
              <IconButton
                onClick={handleOnDelete}
                size="medium"
                edge="start"
                color="error"
                aria-label="menu"
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Box>
      <Box sx={{ height: 20 }} />
      {loading ? (
        <>
          {[1, 2, 3].map((i) => {
            return (
              <>
                <Skeleton
                  variant="rectangular"
                  height={100}
                  animation="wave"
                  sx={{ borderRadius: 2 }}
                />
                <Box sx={{ height: 10 }} />
              </>
            );
          })}
        </>
      ) : wodSets.length > 0 ? (
        // eslint-disable-next-line react/jsx-key
        wodSets.map((set, index) => <SetView set={set} index={index} />)
      ) : (
        <Box>
          <Lottie options={emptyOptions} height={100} width={100} />
          <Typography variant="body2" sx={{ textAlign: "center" }}>
            No sets curated yet for today...
          </Typography>
        </Box>
      )}
      <SystemDialog
        component={
          <Typography variant="overline">
            Are you sure you want to delete the already saved workout ?
          </Typography>
        }
        open={showDeleteConfirmDialog}
        handleClose={() => setShowDeleteConfirmDialog(false)}
        handleConfirm={handleConfirmDialog}
        heading="Delete Workout"
      />
      <SystemDialog
        component={
          <Typography variant="overline">
            Refreshing will delete any unsaved progress that you might have &
            will get todays saved workout. Want to go ahead ?
          </Typography>
        }
        open={showRefreshConfirmDialog}
        handleClose={() => setShowRefreshConfirmDialog(false)}
        handleConfirm={handleRefresh}
        heading="Refresh Workout ?"
      />
    </Box>
  );
};
