import React from "react";
import { Box, Card, Typography, IconButton } from "@mui/material";
import { useWod } from "../context/WODContext";
import { ExerciseSet } from "../pages/company/types";
import { millisToReadableFormat } from "../utils/commons";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import ViewIcon from "@mui/icons-material/Visibility";
import { useTheme } from "@mui/material/styles";

export const SetView = ({
  set,
  index,
  viewOnly,
  isSelected,
  onViewExercisesClicked,
}: {
  isSelected?: boolean;
  viewOnly?: boolean;
  set: ExerciseSet;
  index: number;
  onViewExercisesClicked?: () => void;
}) => {
  const { setCurrentSetIndex, wodSets, setWodSets } = useWod();
  const theme = useTheme();

  const onEditClick = () => {
    if (viewOnly) {
      onViewExercisesClicked?.();
      return;
    }
    setCurrentSetIndex(index);
  };

  const onDeleteClick = () => {
    let remainingWodSets = wodSets.filter((v) => v.id !== set.id);
    remainingWodSets = remainingWodSets.map((ex, i) => {
      return {
        ...ex,
        id: ++i,
      };
    });
    setWodSets(remainingWodSets);
  };

  return (
    <Box sx={{ maxWidth: 350 }}>
      <Card
        variant="outlined"
        sx={{
          background: isSelected
            ? theme.palette.info.light
            : theme.palette.background.paper,
          paddingLeft: 2,
          paddingTop: 2,
          borderRadius: 3,
        }}
      >
        <Typography variant="button">
          {set.name} x {set.repetitions}
        </Typography>
        <Typography variant="subtitle2">
          {millisToReadableFormat(set.duration)}
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton onClick={onEditClick}>
            {viewOnly ? <ViewIcon /> : <EditIcon />}
          </IconButton>
          {!viewOnly && (
            <IconButton onClick={onDeleteClick}>
              <DeleteIcon />
            </IconButton>
          )}
        </Box>
      </Card>
      <Box sx={{ height: 10 }} />
    </Box>
  );
};
