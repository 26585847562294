import { ExerciseSet } from "../pages/company/types";
import moment from "moment";

export const validatePhoneNumber = (number: string): boolean => {
  return /^\d{10,10}$/.test(number);
};

export const millisToReadableFormat = (millis: number): string => {
  let seconds = millis / 1000;
  const minutes = Math.floor(seconds / 60);
  seconds = seconds % 60;
  let readableFormat = "";
  if (minutes > 0) {
    const readableSeconds = getReadableSeconds(seconds);
    if (minutes >= 10) {
      readableFormat = `${minutes}:${readableSeconds}`;
    } else {
      readableFormat = `0${minutes}:${readableSeconds}`;
    }
  } else {
    readableFormat = `00:${getReadableSeconds(seconds)}`;
  }
  return readableFormat;
};

const getReadableSeconds = (seconds: number): String => {
  if (seconds >= 10) {
    return seconds.toString();
  } else {
    return `0${seconds}`;
  }
};

export const getFormattedDate = () => {
  const date = new Date();
  const day = date.getDate();
  const month = date.getMonth() + 1;
  return `${day < 10 ? `0${day}` : day}-${
    month < 10 ? `0${month}` : month
  }-${date.getFullYear()}`;
};

export const getCurrentEpoch = () => {
  return new Date().getTime().toString();
};

export const getWodDuration = (wodExerciseSets: Array<ExerciseSet>) => {
  let duration = 0;
  wodExerciseSets.map((exerciseSet) => {
    duration += exerciseSet.duration;
  });
  return duration;
};

export const getReadableDate = (date: string) => {
  const newDate = new Date();
  newDate.setDate(Number(date.substring(0, 2)));
  newDate.setMonth(Number(date.substring(3, 5)) - 1);
  newDate.setFullYear(Number(date.substring(6, 10)));
  return `${moment(newDate).format("dddd, MMMM Do YYYY")}`;
};

export const getReadableShortDate = (date?: string) => {
  const newDate = new Date();
  if (date) {
    newDate.setDate(Number(date.substring(0, 2)));
    newDate.setMonth(Number(date.substring(3, 5)) - 1);
    newDate.setFullYear(Number(date.substring(6, 10)));
  }
  return `${moment(newDate).format("MMM Do yy")}`;
};

export const getReadableShortTime = (millis: number) => {
  const seconds = Math.floor(millis / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);

  const remainingMinutes = minutes % 60;
  const remainingSeconds = seconds % 60;

  let formattedTime = "";

  if (hours > 0) {
    formattedTime += `${hours}hr `;
  }

  if (remainingMinutes > 0) {
    formattedTime += `${remainingMinutes} mins`;
  }

  if (hours === 0 && remainingMinutes === 0) {
    formattedTime += `${remainingSeconds} secs`;
  }

  return formattedTime.trim();
};
