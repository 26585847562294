import React from "react";
import { Exercise } from "./types";
import { Box, IconButton, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import { millisToReadableFormat } from "../../utils/commons";

export const ExerciseSetList = ({
  viewOnly,
  exercises,
  onExerciseDeleteClicked,
  onExerciseEditClicked,
}: {
  viewOnly?: boolean;
  exercises: Array<Exercise>;
  onExerciseDeleteClicked?: (id: number) => void;
  onExerciseEditClicked?: (id: number) => void;
}) => {
  return (
    <Box sx={{ paddingTop: 2, flex: 1 }}>
      <Typography variant="h5">Exercises</Typography>
      <Box sx={{ height: 20 }}></Box>
      {exercises.map((exercise) => {
        // eslint-disable-next-line react/jsx-key
        return (
          // eslint-disable-next-line react/jsx-key
          <Box>
            <Box
              sx={{
                padding: 1,
                border: 1,
                borderRadius: 4,
                flexDirection: "row",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  flex: 1,
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="button">{exercise.name}</Typography>
                <Typography variant="button">
                  {exercise.type === "TIME_BOUND"
                    ? `${millisToReadableFormat(
                        exercise.duration
                      )} | ${millisToReadableFormat(exercise.breakTime)}`
                    : ` X ${exercise.repetitions}`}
                </Typography>
              </Box>
              {!viewOnly && (
                <IconButton
                  onClick={() => onExerciseEditClicked?.(exercise.id)}
                  sx={{ marginLeft: 5 }}
                  size="medium"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                >
                  <EditIcon />
                </IconButton>
              )}
              {!viewOnly && (
                <IconButton
                  onClick={() => onExerciseDeleteClicked?.(exercise.id)}
                  sx={{ marginLeft: 2 }}
                  size="medium"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </Box>
            <Box sx={{ height: 10 }}></Box>
          </Box>
        );
      })}
    </Box>
  );
};
