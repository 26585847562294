import React, { createContext, useState, useEffect, useContext } from "react";
import { ExerciseList, ExerciseSet } from "../pages/company/types";
import { getFormattedDate } from "../utils/commons";

interface IWODContext {
  wodSets: Array<ExerciseSet>;
  setWodSets: (sets: Array<ExerciseSet>) => void;
  exercises: ExerciseList;
  setExercises: (exercises: ExerciseList) => void;
  currentSetIndex: number;
  setCurrentSetIndex: (index: number) => void;
  loading: boolean;
  setWodsLoading: (loading: boolean) => void;
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
  wodDate: string;
  setWodDate: (dateString: string) => void;
  resetWodToToday: () => void;
}

const WODContext = createContext<IWODContext>({
  wodSets: [],
  setWodSets: () => {},
  exercises: [],
  setExercises: () => {},
  currentSetIndex: -1,
  setCurrentSetIndex: (index: number) => {},
  loading: false,
  setWodsLoading: () => {},
  isEditing: false,
  setIsEditing: (editing: boolean) => {},
  wodDate: getFormattedDate(),
  setWodDate: (date: string) => {},
  resetWodToToday: () => {},
});

export const useWod = (): IWODContext => useContext(WODContext);

export const WODProvider = ({ children }: { children: JSX.Element }) => {
  const [wodSets, setWodSets] = useState<Array<ExerciseSet>>([]);
  const [exercises, setExercises] = useState<ExerciseList>([]);
  const [currentSetIndex, setCurrentSetIndex] = useState<number>(-1);
  const [loading, setWodsLoading] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [wodDate, setWodDate] = useState<string>(getFormattedDate());

  const resetWodToToday = () => {
    setWodDate(getFormattedDate());
    setIsEditing(false);
  };

  return (
    <WODContext.Provider
      value={{
        wodSets,
        setWodSets,
        setExercises,
        exercises,
        currentSetIndex,
        setCurrentSetIndex,
        loading,
        setWodsLoading,
        isEditing,
        setIsEditing,
        wodDate,
        setWodDate,
        resetWodToToday,
      }}
    >
      {children}
    </WODContext.Provider>
  );
};
