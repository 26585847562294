import React from "react";
import { withAuth } from "../../hoc";
import Lottie from "react-lottie";
import WIPImage from "../../assets/wip.json";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: WIPImage,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
const Dashboard = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        height: "60vh",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        display: "flex",
      }}
    >
      <Lottie options={defaultOptions} height={600} width={600} />

      <Typography variant="h6" sx={{ color: theme.palette.secondary.main }}>
        We are working on some exciting features for you! Hang tight until we
        furnish them for you!
      </Typography>
    </Box>
  );
};

export default withAuth(Dashboard);
