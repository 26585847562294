import { useEffect, useRef, useState } from "react";
import {
  ApplicationVerifier,
  ConfirmationResult,
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  UserCredential,
} from "firebase/auth";

export interface IPhoneVerification {
  setPhoneNumber: (number: string) => void;
  verificationStatus: VerificationStatus;
  getOTP: () => void;
  verifyOTP: (otp: string) => Promise<void>;
  setVerificationStatus: (status: VerificationStatus) => void;
}

export enum VerificationStatus {
  UNVERIFIED = "UNVERIFIED",
  VERIFIED = "VERIFIED",
  SMS_SENT = "SMS_SENT",
  VERIFICATION_FAILED = "VERIFICATION_FAILED",
  RESEND_OTP_REQUIRED = "RESEND_OTP_REQUIRED",
  VERIFYING = "VERIFYING",
}

const usePhoneVerification = (otpButtonId: string): IPhoneVerification => {
  const auth = getAuth();

  let recaptchaVerifier = useRef<RecaptchaVerifier>();
  let confirmationResult = useRef<ConfirmationResult>();

  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [verificationStatus, setVerificationStatus] =
    useState<VerificationStatus>(VerificationStatus.UNVERIFIED);

  const getOTP = () => {
    signInWithPhoneNumber(
      auth,
      `+91${phoneNumber}`,
      recaptchaVerifier.current as ApplicationVerifier
    )
      .then((confirmationResultInstance: ConfirmationResult) => {
        // console.log("SMS SENT");
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        setVerificationStatus(VerificationStatus.SMS_SENT);
        confirmationResult.current = confirmationResultInstance;
      })
      .catch((error) => {
        // console.log("### Error Occured in SignInWithPhoneNumber ", error);
        setVerificationStatus(VerificationStatus.VERIFICATION_FAILED);
      });
  };

  useEffect(() => {
    try {
      recaptchaVerifier.current = new RecaptchaVerifier(
        otpButtonId,
        {
          size: "invisible",
          callback: (response: any) => {
            // reCAPTCHA solved, allow signInWithPhoneNumber.
            // console.log("#### hello! captcha is great!", response);
          },
          "expired-callback": () => {
            console.log("hellolllllll");
            // Response expired. Ask user to solve reCAPTCHA again.
            // ...
          },
        },
        auth
      );
      recaptchaVerifier.current?.render();
    } catch (e) {
      console.log("### e", e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const verifyOTP = async (code: string): Promise<void> => {
    setVerificationStatus(VerificationStatus.VERIFYING);
    try {
      const confirmation: UserCredential | undefined =
        await confirmationResult.current?.confirm(code);
      console.log("#### verified : ");
      if (confirmation) {
        setVerificationStatus(VerificationStatus.VERIFIED);
      }
    } catch (error) {
      console.log("### Error in verifying OTP: ", error);
      setVerificationStatus(VerificationStatus.VERIFICATION_FAILED);
    }
  };

  return {
    setPhoneNumber,
    verifyOTP,
    verificationStatus,
    getOTP,
    setVerificationStatus,
  };
};

export default usePhoneVerification;
