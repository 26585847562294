import { Box, Typography, Tooltip, IconButton } from "@mui/material";
import React, { useState } from "react";
import Lottie from "react-lottie";
import RocketImage from "../../assets/empty.json";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import ApplyIcon from "@mui/icons-material/SaveAltRounded";
import { SetView } from "../../components/SetView";
import {
  getReadableShortDate,
  getReadableShortTime,
} from "../../utils/commons";
import { ExerciseSetList } from "./ExerciseSetList";
import { WOD } from "./types";
import { useTheme } from "@mui/material/styles";

const emptyOptions = {
  loop: true,
  autoplay: true,
  animationData: RocketImage,
};

export const WODView = ({
  wod,
  onDeleteWod,
  onCloneClicked,
}: {
  wod: WOD;
  onDeleteWod: () => void;
  onCloneClicked: () => void;
}) => {
  const [currentSetIndex, setCurrentSetIndex] = useState<number>(-1);
  const theme = useTheme();
  let exercises =
    currentSetIndex > -1 ? wod.sets[currentSetIndex].exercises : [];

  const handleViewSet = (index: number): void => {
    setCurrentSetIndex(index);
  };

  const handleCloneForToday = () => {
    onCloneClicked();
  };

  return (
    <Box
      sx={{
        flexDirection: "row",
        display: "flex",
        flex: 1,
        paddingRight: 2,
        paddingLeft: 10,
      }}
    >
      <Box
        sx={{
          flexDirection: "column",
          width: "50%",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography variant="body2">{`Workout plan`}</Typography>
            <Box sx={{ height: 5 }} />
            <Typography
              variant="subtitle1"
              sx={{ color: theme.palette.secondary.dark }}
            >
              {getReadableShortDate(wod.date)} |{" "}
              {getReadableShortTime(Number(wod.duration || 0))}
            </Typography>
          </Box>
          {wod.sets.length > 0 && (
            <Box>
              <Tooltip sx={{ marginRight: 2 }} title="Use for Today">
                <IconButton
                  onClick={handleCloneForToday}
                  size="medium"
                  edge="start"
                  color="primary"
                  aria-label="menu"
                >
                  <ApplyIcon />
                </IconButton>
              </Tooltip>
              <Tooltip sx={{ marginRight: 2 }} title="Delete">
                <IconButton
                  onClick={onDeleteWod}
                  size="medium"
                  edge="start"
                  color="error"
                  aria-label="menu"
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        </Box>
        <Box sx={{ height: 20 }} />
        {wod.sets.length > 0 ? (
          // eslint-disable-next-line react/jsx-key
          wod.sets.map((set, index) => (
            <SetView
              key={`${set.id}_${index}`}
              viewOnly
              set={set}
              isSelected={currentSetIndex === index}
              onViewExercisesClicked={() => handleViewSet(index)}
              index={index}
            />
          ))
        ) : (
          <Box>
            <Lottie options={emptyOptions} height={100} width={100} />
            <Typography variant="body2" sx={{ textAlign: "center" }}>
              No sets curated yet for today...
            </Typography>
          </Box>
        )}
      </Box>
      <Box sx={{ width: 10 }} />
      {currentSetIndex !== -1 && (
        <ExerciseSetList viewOnly exercises={exercises} />
      )}
    </Box>
  );
};
