import * as React from "react";
import { logEvent, Analytics } from "firebase/analytics";

interface Props {
  children: React.ReactNode;
  analyticsInstance: Analytics;
}

export interface IAnalyticsContext {
  logCustomEvent: (eventName: string, eventData?: object) => void;
}

export const AnalyticsContext = React.createContext<IAnalyticsContext>({
  logCustomEvent: () => {},
});

export const AnalyticsProvider = ({ children, analyticsInstance }: Props) => {
  const logCustomEvent = (eventName: string, eventData?: object): void => {
    logEvent(analyticsInstance, eventName, eventData);
  };

  return (
    <AnalyticsContext.Provider
      value={{
        logCustomEvent,
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};
