import {
  Grid,
  TextField,
  InputAdornment,
  Button,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Timer from "../../components/Timer";
import { usePhoneVerification, VerificationStatus } from "../../hooks";
import { validatePhoneNumber } from "../../utils";

interface Props {
  onUserVerified: () => void;
}

const Step1Form = ({ onUserVerified }: Props): JSX.Element => {
  const [number, setNumber] = useState<string>("");
  const [otp, setOtp] = useState<string>("");
  const [canEnterOTP, setCanEnterOTP] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const {
    setPhoneNumber,
    verificationStatus,
    setVerificationStatus,
    getOTP,
    verifyOTP,
  } = usePhoneVerification("get-otp");

  useEffect(() => {
    if (verificationStatus === VerificationStatus.SMS_SENT) {
      setCanEnterOTP(true);
      setOtp("");
    } else if (verificationStatus === VerificationStatus.UNVERIFIED) {
      setCanEnterOTP(false);
    } else if (verificationStatus === VerificationStatus.VERIFIED) {
      onUserVerified();
    } else if (verificationStatus === VerificationStatus.VERIFICATION_FAILED) {
      setOtp("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verificationStatus]);

  const handlePhoneInput = (event: any) => {
    if (event.target.value.length >= 10) {
      setError(!validatePhoneNumber(event.target.value));
    }
    setPhoneNumber(event.target.value);
    setNumber(event.target.value);
  };

  const handleOTPChange = (event: any) => {
    setOtp(event.target.value);
    if (event.target.value.length === 6) {
      verifyOTP(event.target.value);
    }
  };

  const OTPButtonElement = (): JSX.Element => {
    switch (verificationStatus) {
      case VerificationStatus.SMS_SENT:
        return (
          <Timer
            timeInSeconds={60}
            onTimerFinish={() =>
              setVerificationStatus(VerificationStatus.RESEND_OTP_REQUIRED)
            }
          />
        );
      case VerificationStatus.RESEND_OTP_REQUIRED:
        return <>RESEND OTP</>;
      default:
        return <>GET OTP</>;
    }
  };

  return (
    <Grid
      sx={{ padding: 2 }}
      flexGrow={1}
      display="flex"
      flexDirection="column"
    >
      <TextField
        margin="normal"
        required
        error={error}
        helperText={error && "Please enter a valid number"}
        disabled={verificationStatus === VerificationStatus.SMS_SENT}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">+91 </InputAdornment>
          ),
          endAdornment: (
            <Button
              disabled={
                !validatePhoneNumber(number) ||
                verificationStatus === VerificationStatus.SMS_SENT
              }
              type="submit"
              color="success"
              variant="outlined"
              id="get-otp"
              onClick={getOTP}
              sx={{ minWidth: 100, fontSize: 12 }}
            >
              <OTPButtonElement />
            </Button>
          ),
        }}
        fullWidth
        type={"text"}
        onBlur={() => {
          setError(!validatePhoneNumber(number));
        }}
        id="phone"
        onChange={handlePhoneInput}
        label="Phone Number"
        name="phone-number"
      />
      <TextField
        margin="normal"
        disabled={!canEnterOTP}
        required
        error={verificationStatus === VerificationStatus.VERIFICATION_FAILED}
        helperText={
          verificationStatus === VerificationStatus.VERIFICATION_FAILED &&
          "Incorrect OTP"
        }
        fullWidth
        name="password"
        label="OTP"
        value={otp}
        inputProps={{
          maxLength: 6,
        }}
        InputProps={{
          endAdornment: verificationStatus === VerificationStatus.VERIFYING && (
            <CircularProgress />
          ),
        }}
        onChange={handleOTPChange}
        id="password"
      />
    </Grid>
  );
};

export default Step1Form;
