import React from "react";
import { Grid, Typography, Paper } from "@mui/material";
import Lottie from "react-lottie";

import RegisterStep1Form from "./OTPVerification";

import RocketImage from "../../assets/gym_login_animation.json";
import { withAuth } from "../../hoc";

const Register = () => {
  const RightInfographic = () => {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: RocketImage,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    return (
      <Grid
        sx={{
          padding: 2,
          display: { xs: "none", sm: "flex", md: "flex" },
        }}
        flexGrow={1}
        display="flex"
        flexDirection="column"
      >
        <Lottie options={defaultOptions} height={200} width={200} />
      </Grid>
    );
  };

  return (
    <Grid
      height="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        sx={{
          padding: 4,
          borderRadius: 4,
        }}
        component={Paper}
        elevation={2}
      >
        <Typography sx={{ paddingBottom: 2 }} component="h1" variant="h3">
          Hi!
        </Typography>
        <Typography component="h1" variant="h4">
          Please verify your Mobile number
        </Typography>

        <Typography component="h1" variant="h6">
          to continue...
        </Typography>

        <Grid sx={{ paddingTop: 4 }} item display="flex" flexDirection="row">
          <RegisterStep1Form
            onUserVerified={() => {
              console.log("Verified!!!");
            }}
          />
          <RightInfographic />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withAuth(Register, false);
