import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import ViewIcon from "@mui/icons-material/Visibility";

import EditIcon from "@mui/icons-material/Edit";

import Paper from "@mui/material/Paper";
import { Box, IconButton, Typography } from "@mui/material";
import {
  cloneWorkout,
  deleteWod,
  fetchPastWods,
} from "../../services/WodService";
import { WOD } from "./types";
import { getReadableDate, getReadableShortTime } from "../../utils/commons";
import { useWod } from "../../context/WODContext";
import Lottie from "react-lottie";
import RocketImage from "../../assets/empty.json";
import LoadingImage from "../../assets/loading.json";
import { WODView } from "./WODView";
import { SystemDialog } from "../../utils/Dialog";
import { useSnackbar } from "notistack";

const emptyOptions = {
  loop: true,
  autoplay: true,
  animationData: RocketImage,
};

const loadingOptions = {
  loop: true,
  autoplay: true,
  animationData: LoadingImage,
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.palette.action.hover,
  maxHeight: 100,
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

interface IViewWods {
  onEditWodSelected: () => void;
}

const ViewWODs = ({ onEditWodSelected }: IViewWods) => {
  const [wods, setWods] = useState<Array<WOD>>([]);
  const { setWodSets, setIsEditing: setWodIsEditing, setWodDate } = useWod();
  const [loading, setLoading] = useState<boolean>(false);
  const [currentWod, setCurrentWod] = useState<WOD | undefined>(undefined);

  const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] =
    useState<boolean>(false);

  const [showWODClone, setShowWODClone] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();

  const getPastWorkouts = () => {
    setLoading(true);
    fetchPastWods()
      .then((docs) => {
        const allWods: Array<WOD> = docs as Array<WOD>;
        setWods(allWods);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        alert("Something went wrong! Please try again later...");
      });
  };

  useEffect(() => {
    getPastWorkouts();
  }, []);

  const onEditClicked = (wod: WOD) => {
    setWodSets(wod.sets);
    setWodIsEditing(true);
    setWodDate(wod.date);
    onEditWodSelected();
  };

  const onViewClicked = (wod: WOD) => {
    setCurrentWod(wod);
  };

  const onDeletWodClicked = (wod: WOD) => {
    setCurrentWod(wod);
    onWodDeleteClicked();
  };

  const onWodDeleteClicked = () => {
    setShowDeleteConfirmDialog(true);
  };

  const handleCloneClicked = () => {
    setShowWODClone(true);
  };

  const handleDeleteConfirm = () => {
    deleteWod(currentWod?.date)
      .then(() => {
        setShowDeleteConfirmDialog(false);
        enqueueSnackbar("WOD deleted successfully!");
        setCurrentWod(undefined);
        getPastWorkouts();
      })
      .catch(() => {
        setShowDeleteConfirmDialog(false);
      });
  };

  const handleWODCloneConfirm = () => {
    if (currentWod) {
      cloneWorkout(currentWod)
        .then((cloned) => {
          enqueueSnackbar("WOD saved successfully!");
          getPastWorkouts();
          setShowWODClone(false);
        })
        .catch((e) => {
          alert("Something went wrong. Please try again later");
        });
    }
  };

  const EmptyData = () => {
    return (
      <Box style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
        <Lottie options={emptyOptions} height={100} width={100} />
        <Typography variant="body2" sx={{ textAlign: "center" }}>
          No workouts found. Please go ahead and create one to view here...
        </Typography>
      </Box>
    );
  };

  const Loading = () => {
    return (
      <Box style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
        <Lottie options={loadingOptions} height={300} width={300} />
        <Typography variant="body2" sx={{ textAlign: "center" }}>
          Hang tight! We are trying to get your past workouts list...
        </Typography>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        maxWidth: "100%",
        maxHeight: "80vh",
        flexDirection: "row",
        paddingTop: 4,
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: "flex",
        }}
      >
        {wods.length === 0 || loading ? (
          loading ? (
            <Loading />
          ) : (
            <EmptyData />
          )
        ) : (
          <TableContainer
            sx={{ minHeight: "60vh", maxHeight: "60vh" }}
            component={Paper}
          >
            <Table sx={{ maxWidth: "100%" }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Workout Plan Date</TableCell>
                  <TableCell align="center">Workout Duration</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {wods.map((wod) => (
                  <StyledTableRow
                    key={wod.date}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <StyledTableCell component="th" scope="row">
                      {getReadableDate(wod.date).toString()}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography>
                        {getReadableShortTime(Number(wod?.duration))}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <IconButton
                        onClick={() => onViewClicked(wod)}
                        sx={{ paddingRight: 1, marginRight: 3 }}
                        size="medium"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                      >
                        <ViewIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => onEditClicked(wod)}
                        sx={{ paddingRight: 1, marginRight: 3 }}
                        size="medium"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => onDeletWodClicked(wod)}
                        sx={{ paddingRight: 1 }}
                        size="medium"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                      >
                        <DeleteIcon />
                      </IconButton>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
      <Box sx={{ flex: 1, display: "flex" }}>
        {currentWod ? (
          <WODView
            onDeleteWod={onWodDeleteClicked}
            onCloneClicked={handleCloneClicked}
            wod={currentWod}
          />
        ) : (
          <></>
        )}
      </Box>
      <SystemDialog
        component={
          <Typography variant="overline">
            {`Are you sure you want to delete this workout ?`}
          </Typography>
        }
        open={showDeleteConfirmDialog}
        handleClose={() => setShowDeleteConfirmDialog(false)}
        handleConfirm={handleDeleteConfirm}
        heading="Delete Workout"
      />
      <SystemDialog
        component={
          <Typography variant="overline">
            {`Do you want to use this workout plan for today ?`}
          </Typography>
        }
        open={showWODClone}
        handleClose={() => setShowWODClone(false)}
        handleConfirm={handleWODCloneConfirm}
        heading="Copy Workout"
      />
    </Box>
  );
};

export default ViewWODs;
