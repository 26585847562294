import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import React from "react";

export interface IComponentnDialog {
  heading: string;
  open: boolean;
  component: React.ReactElement;
  handleClose: () => void;
  handleConfirm: () => void;
}
export const SystemDialog = ({
  open,
  handleClose,
  handleConfirm,
  heading,
  component,
}: IComponentnDialog) => {
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{heading}</DialogTitle>
        <DialogContent>{component}</DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleClose();
            }}
            autoFocus
          >
            No
          </Button>
          <Button
            onClick={() => {
              handleConfirm();
            }}
            autoFocus
          >
            Yes, go ahead
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
