import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import WorkoutOfTheDay from "../pages/company/WorkoutOfTheDay";
import Register from "../pages/verifyuser/VerifyUser";
import { Box, Divider } from "@mui/material";
import { useAuth } from "../context/AuthContext";
import AppHeader from "../components/UserHeader";
import { Drawer } from "./Drawer";
import Dashboard from "../pages/company/Dashboard";
import Exercises from "../pages/company/Exercises";

const Navigation = () => {
  const { isAuthenticated } = useAuth();
  return (
    <BrowserRouter>
      <Box
        sx={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {isAuthenticated && <AppHeader />}
        <Box
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: "row",
          }}
        >
          {isAuthenticated && <Drawer />}
          <Divider />
          <Box style={{ flex: 1 }}>
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="verify" element={<Register />} />
              <Route path="exercise" element={<Exercises />} />
              <Route path="wod" element={<WorkoutOfTheDay />} />
              <Route path="*" element={<Dashboard />} />
            </Routes>
          </Box>
        </Box>
      </Box>
    </BrowserRouter>
  );
};

export default Navigation;
