/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/react-in-jsx-scope */
import { WODProvider } from "../../context/WODContext";
import { withAuth } from "../../hoc";
import { WOD } from "./WOD";

const WorkoutOfTheDay = () => {
  return (
    <WODProvider>
      <WOD />
    </WODProvider>
  );
};

export default withAuth(WorkoutOfTheDay);
