import React from "react";
import { useAuth } from "../context/AuthContext";
import { useLocation, Navigate } from "react-router-dom";

const withAuth = (
  WrappedComponent: () => JSX.Element,
  needAuth: boolean = true
): (() => JSX.Element) => {
  // eslint-disable-next-line react/display-name
  return (): JSX.Element => {
    const { isAuthenticated } = useAuth();
    const location = useLocation();

    if (!isAuthenticated && needAuth) {
      return <Navigate to="/verify" state={{ from: location }} replace />;
    }

    if (isAuthenticated && !needAuth) {
      return <Navigate to="/dashboard" state={{ from: location }} replace />;
    }

    return <WrappedComponent />;
  };
};

export default withAuth;
